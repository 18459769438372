import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthProvider';

const DraftMockResult = ({ onScoreCalculated }) => {
    const { user } = useAuth();
    const userId = user.user_id;
    const mocktestId = '1';
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [mocktestResults, setMocktestResults] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [finalScore, setFinalScore] = useState(0);
    const [level, setLevel] = useState('');
    const [showReasons, setShowReasons] = useState(false);  // State to toggle reasons visibility

    
    // Helper function to extract the score and reason from the result text
    const extractScoreAndReason = (resultText) => {
        const scoreMatch = resultText.match(/Score: (\d+)/); // Match the score part
        const reasonMatch = resultText.match(/Reason: (.+)/); // Match the reason part

        const score = scoreMatch ? parseInt(scoreMatch[1], 10) : 0;
        const reason = reasonMatch ? reasonMatch[1] : "Reason not found";

        return { score, reason };
    };

    // Function to calculate total score and determine level
    const calculateScore = (scores) => {
        let totalScore = scores.reduce((acc, score) => acc + score, 0);
        let weightedScore = Math.round(totalScore * 5.7);

        setFinalScore(weightedScore);
        onScoreCalculated(weightedScore);

        let calculatedLevel = '';
        if (weightedScore >= 200) calculatedLevel = 'AH (Level 8)';
        else if (weightedScore >= 190) calculatedLevel = 'AM (Level 8)';
        else if (weightedScore >= 180) calculatedLevel = 'AM (Level 7)';
        else if (weightedScore >= 160) calculatedLevel = 'AL (Level 7)';
        else if (weightedScore >= 150) calculatedLevel = 'IH (Level 6)';
        else if (weightedScore >= 140) calculatedLevel = 'IH (Level 6)';
        else if (weightedScore >= 130) calculatedLevel = 'IM3 (Level 6)';
        else if (weightedScore >= 120) calculatedLevel = 'IM2 (Level 5)';
        else if (weightedScore >= 110) calculatedLevel = 'IM1 (Level 5)';
        else if (weightedScore >= 100) calculatedLevel = 'IL (Level 4)';
        else if (weightedScore >= 60) calculatedLevel = 'NH (Level 3)';
        else if (weightedScore >= 30) calculatedLevel = 'NM (Level 2)';
        else calculatedLevel = 'NL (Level 1)';

        setLevel(calculatedLevel);
    };

    // Fetch mock test results from the API and process score/reason
    const fetchMocktestResults = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/get-mocktest-result/${userId}/${mocktestId}`);

            // Extract score and reason from each part's result text
            const part2_q3 = extractScoreAndReason(response.data.part2_q3);
            const part2_q4 = extractScoreAndReason(response.data.part2_q4);
            const part3_q5 = extractScoreAndReason(response.data.part3_q5);
            const part3_q6 = extractScoreAndReason(response.data.part3_q6);
            const part3_q7 = extractScoreAndReason(response.data.part3_q7);
            const part4_q8 = extractScoreAndReason(response.data.part4_q8);
            const part4_q9 = extractScoreAndReason(response.data.part4_q9);
            const part4_q10 = extractScoreAndReason(response.data.part4_q10);
            const part5_q11 = extractScoreAndReason(response.data.part5_q11);

            // Combine all the scores into an array for calculation
            const scores = [
                part2_q3.score,
                part2_q4.score,
                part3_q5.score,
                part3_q6.score,
                part3_q7.score,
                part4_q8.score,
                part4_q9.score,
                part4_q10.score,
                part5_q11.score,
            ];

            setMocktestResults({
                part2_q3,
                part2_q4,
                part3_q5,
                part3_q6,
                part3_q7,
                part4_q8,
                part4_q9,
                part4_q10,
                part5_q11,
            });

            // Calculate the final score and level
            calculateScore(scores);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching mock test results:', error);
            setError('Error fetching mock test results.');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMocktestResults();
    }, []);

    const toggleReasons = () => setShowReasons(!showReasons);


    if (loading) return <div>Loading results...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="w-full rounded-lg">
          {/* <h1 className="text-2xl font-bold text-center mb-8 text-gray-800">Mock Test Results</h1> */}
          {mocktestResults && (
            <div className="space-y-6">
              {/* Toggle button for showing reasons */}
              <button
                className="px-4 py-2 rounded-lg bg-[#F5F0E5] text-gray-700 text-md w-full text-center mb-6"
                onClick={toggleReasons}
              >
                {showReasons ? "숨기기" : "각 문제별 점수에 대한 채점 분석 확인"}
              </button>
      
              {/* Results by Part */}
              {["Part 2 Results", "Part 3 Results", "Part 4 Results", "Part 5 Results"].map((partTitle, partIndex) => (
                <div key={partIndex}>
                  <h2 className="font-semibold text-xl text-gray-800 mb-4">{partTitle}</h2>
                  {Object.entries(mocktestResults)
                    .filter(([key]) => key.startsWith(`part${partIndex + 2}_`))
                    .map(([key, result]) => (
                      <div key={key} className="bg-blue-50 border border-blue-200 p-4 rounded-md mb-4">
                        <p className="text-md font-semibold text-gray-700">
                          <span className="text-blue-500 font-bold">{key.replace(/_/g, ' ').toUpperCase()}</span> - Score: {result.score}
                        </p>
                        {showReasons && (
                          <p className="text-sm text-gray-600 mt-2">
                            {result.reason}
                          </p>
                        )}
                      </div>
                    ))}
                </div>
              ))}
            </div>
          )}
        </div>
      );
};

export default DraftMockResult;