import React from 'react';
import {TailwindStatics} from '../statics';

const PrimaryButton = ({ 
    iconPath = null,
    text, 
    textColor,
    fontSize = TailwindStatics.BIG_TEXT,
    backgroundColor = '#ffffff',
    iconPosition,
    onClick
  }) => {
    return (
      <button
        className={`flex items-center justify-center space-x-2 font-sans font-semibold w-full max-w-lg ${fontSize} ${TailwindStatics.MINIMUM_WIDTH_200}`}
        style={{
            backgroundColor: backgroundColor, 
            color: textColor,
            padding: '16px 24px',
            borderRadius: '8px'
        }}
        onClick={onClick}
        >
        {iconPosition === 'left' && 
        <span className="mr-1">
            <img src={iconPath} alt="Icon" className="w-6 h-6" />
        </span>}
        <span>{text}</span>
        {iconPosition === 'right' && 
        <span className="ml-1">
            <img src={iconPath} alt="Icon" className="w-6 h-6" />
        </span>}
      </button>
    );
  };

export default PrimaryButton;