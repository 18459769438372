// src/pages/PricingPage.js
import React from 'react';
import PricingCard from '../components/PricingCard';

const PricingPage = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center p-8">
      <h1 className="text-5xl font-bold mb-12 font-['Sansita']">Pricing</h1>
      <div className="grid gap-8 grid-cols-1 md:grid-cols-3 w-full max-w-6xl">
        <PricingCard 
          title="Basic Plan" 
          price="$10/month" 
          features={[
            'Feature 1',
            'Feature 2',
            'Feature 3',
          ]} 
        />
        <PricingCard 
          title="Pro Plan" 
          price="$20/month" 
          features={[
            'Pro Feature 1',
            'Pro Feature 2',
            'Pro Feature 3',
          ]} 
        />
        <PricingCard 
          title="Enterprise Plan" 
          price="$50/month" 
          features={[
            'Enterprise Feature 1',
            'Enterprise Feature 2',
            'Enterprise Feature 3',
          ]} 
        />
      </div>
    </div>
  );
};

export default PricingPage;