import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { useAuth } from '../context/AuthProvider';
import CustomAudioPlayer from '../components/CustomAudioPlayer';
import { ContentContainerBox } from '../components/Container';
import { DoughnutChart } from '../components/Part1Components';
import DraftMockResult from './DraftMockResult';
import { SparklesIcon, HomeIcon } from '@heroicons/react/24/outline';

const MockTestCompleted = ({ mocktestId }) => {
  const navigate = useNavigate();

    // const user = {
    //     user_id: 'jbae49' // Temporary hardcoded user ID
    // };

    const { user } = useAuth();

    // Track page load for Mixpanel
  useEffect(() => {
    mixpanel.track('Mock Test Completed Page Loaded', {
      mocktestId: mocktestId,
      userId: user.user_id
    });
  }, [mocktestId, user.user_id]);


    const [azureResults, setAzureResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [finalScore, setFinalScore] = useState(0);
    const [draftMockScore, setDraftMockScore] = useState(0);
    const [level, setLevel] = useState('');
    const [isDraftMockLoading, setIsDraftMockLoading] = useState(true);  // New state for DraftMockResult loading

    useEffect(() => {
        const fetchTranscriptions = async () => {
            try {
                const trans = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/mocktest/${mocktestId}/transcriptions`, {
                    params: { user_id: user.user_id }
                });

                const keysToExtract = ['mock1_q01', 'mock1_q02'];
                const extractedList = keysToExtract.map(key => trans.data[key]).filter(value => value !== undefined);
                setAzureResults(extractedList);
            } catch (error) {
                console.error("Error fetching transcriptions:", error);
            } finally {
                setLoading(false);
            }
        };

        if (user && user.user_id) {
            fetchTranscriptions();
        }
    }, [mocktestId, user.user_id]);

    const calculatePart1Score = (averageScore) => {
        if (averageScore > 90) return 17.1;
        if (averageScore > 75) return 11.4;
        if (averageScore > 50) return 5.7;
        return 0;
    };

    const handleDraftMockScoreCalculated = (score) => {
    setDraftMockScore(score);
    setIsDraftMockLoading(false);  // Mark DraftMockResult as loaded
    if (azureResults.length > 0) {
        const part1Scores = azureResults.map(result => calculatePart1Score(result.azure.average_score));
        const part1TotalScore = part1Scores.reduce((acc, score) => acc + score, 0);
        const totalScore = score + part1TotalScore;

        // Round the final score to the nearest 10
        const roundedScore = Math.round(totalScore / 10) * 10;
        setFinalScore(roundedScore);
        calculateLevel(roundedScore);
    }
};

    const calculateLevel = (score) => {
        let calculatedLevel = '';
        if (score >= 200) calculatedLevel = 'AH (Level 8)';
        else if (score >= 190) calculatedLevel = 'AM (Level 8)';
        else if (score >= 180) calculatedLevel = 'AM (Level 7)';
        else if (score >= 160) calculatedLevel = 'AL (Level 7)';
        else if (score >= 150) calculatedLevel = 'IH (Level 6)';
        else if (score >= 140) calculatedLevel = 'IH (Level 6)';
        else if (score >= 130) calculatedLevel = 'IM3 (Level 6)';
        else if (score >= 120) calculatedLevel = 'IM2 (Level 5)';
        else if (score >= 110) calculatedLevel = 'IM1 (Level 5)';
        else if (score >= 100) calculatedLevel = 'IL (Level 4)';
        else if (score >= 60) calculatedLevel = 'NH (Level 3)';
        else if (score >= 30) calculatedLevel = 'NM (Level 2)';
        else calculatedLevel = 'NL (Level 1)';

        setLevel(calculatedLevel);
    };

    if (loading) {
        return <div>Loading transcriptions...</div>;
    }

    return (
      <div className="min-h-screen bg-gray-100 p-6 flex flex-col items-center">
        {/* Home Button */}
        <div className="w-full flex justify-start mb-4">
          <button onClick={() => navigate('/')}>
            <HomeIcon className="w-6 h-6 text-gray-600" />
          </button>
        </div>
    
        {/* Loading Message */}
        {isDraftMockLoading && (
          <div className="text-center text-lg text-orange-600 mt-4 mb-4">
            점수 분석 중입니다... 약 10초 소요됩니다.
          </div>
        )}
    
        {/* Final Score and Level */}
        <div className="w-full max-w-xl bg-white p-6 rounded-lg text-center mt-4 mb-8">
          <h3 className="text-2xl font-bold mb-2 text-gray-800">최종 점수</h3>
          <p className="text-3xl font-semibold text-blue-500 mb-4">{finalScore}</p>
          <h4 className="text-lg text-gray-600">레벨: {level}</h4>
        </div>
    
        {/* Part 1 Results */}
        <div className="w-full rounded-lg  mb-8">
          <h2 className="text-xl font-semibold text-center text-gray-800 mb-4">파트 1 결과</h2>
          {azureResults.length > 0 ? (
            <div className="flex flex-col">
              {azureResults.map((result, index) => (
                <AzureResultContainer key={index} azureAiScore={result.azure} />
              ))}
            </div>
          ) : (
            <p className="text-center text-gray-500">파트 1 결과를 로드하는 중입니다...</p>
          )}
        </div>
    
        {/* Draft Mock Results (Part 2-5) */}
        <div className="w-full max-w-xl bg-white p-6 rounded-lg">
          <h2 className="text-xl font-semibold text-center text-gray-800 mb-4">파트 2-5 결과</h2>
          <DraftMockResult onScoreCalculated={handleDraftMockScoreCalculated} />
        </div>
      </div>
  );
};

const AzureResultContainer = ({ azureAiScore }) => (
  <ContentContainerBox>
      <p className='text-center'>
        {azureAiScore.question_id.split('_')[1]}  {/* This will split the string by underscore and take the second part */}
      </p>
      {/* <div className="flex justify-center text-md font-sans font-semibold">
          Overall Score
      </div> */}
      <DoughnutChart score={azureAiScore.average_score} />
      <div className="text-md font-sans mb-10">
          <p className="font-['Inter']">유창성: {azureAiScore.fluency_score}</p>
          <p className="font-['Inter']">정확성: {azureAiScore.accuracy_score}</p>
          <p className="font-['Inter']">억양: {azureAiScore.prosody_score}</p>
          <p className="font-['Inter']">완성도: {azureAiScore.completeness_score}</p>
          <p className="font-['Inter']" style={{ fontWeight: 'bold' }}>평균: {azureAiScore.average_score}</p>
      </div>
  </ContentContainerBox>
);

export default MockTestCompleted;