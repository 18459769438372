import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const MockTestPart3Narrator = ({ mocktestId }) => {
  const audioRef = useRef(null);
  const [narratorSentence, setNarratorSentence] = useState('');
  const [question, setQuestion] = useState('');
  const navigate = useNavigate();
  const { questionIndex } = useParams();  // Get questionIndex from URL parameters

  useEffect(() => {
    // Fetch the narrator's sentence and question for the current index
    const fetchNarratorData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/mocktest${mocktestId}`);
        const data = response.data.part3;
        setNarratorSentence(data.narrator);  // Fetch the narrator's sentence
        setQuestion(data.questions[questionIndex].text);  // Fetch the current question based on questionIndex
      } catch (error) {
        console.error('Error fetching narrator data:', error);
      }
    };

    fetchNarratorData();
  }, [mocktestId, questionIndex]);

  console.log("questionIndex", questionIndex);
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.play().catch((err) => console.error("Audio playback error:", err));
      // Navigate to the problems page for the same questionIndex (do not increment)
      audioRef.current.onended = () => navigate(`/mocktest${mocktestId}/part3-problems/${questionIndex}`);
    }
  }, [navigate, mocktestId, questionIndex]);

  return (
    <div className="min-h-screen bg-white flex flex-col items-center justify-start">
      {/* Title with gray background */}
      <h1 className="text-xl lg:text-2xl font-bold text-center mb-6 bg-gray-300 w-full py-4">
        Qeustions 5 of 11
      </h1>

      {/* Main content area */}
      <div className="bg-white rounded-lg p-6 lg:p-8 w-full max-w-3xl mb-6">
        <p className="text-base md:text-lg lg:text-xl text-gray-700 mb-6">
          {narratorSentence}
        </p>
        {/* <p className="text-lg md:text-xl lg:text-2xl text-gray-700 mb-6">
          {question}
        </p> */}
        <p
  className="text-lg md:text-xl lg:text-2xl text-gray-700 mb-6"
  dangerouslySetInnerHTML={{
    __html: question.replace(/\n/g, '<br />')
  }}
/>

        {/* Audio player */}
        <audio ref={audioRef} src={`/recordings/mocktest${mocktestId}_p3_q${parseInt(questionIndex, 10) + 1}.mp3`} />
      </div>
    </div>
  );
};

export default MockTestPart3Narrator;