import React from 'react';
import { TailwindStatics} from '../statics';
import { useNavigate } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/24/outline';

const  TopNavigator = ({ title }) => {

    const BackButton = () => {
      const navigate = useNavigate();
      const handleHomeClick = () => {
        navigate('/'); 
      };

      return (
        <button 
          className="absolute top-4 left-4 text-xl" 
          onClick={handleHomeClick}
          style={{ maxHeight: '56px', maxWidth: '56px' }} >
            <HomeIcon className="w-6 h-6" />
        </button>
      );
    };
  return (
    <div className={`fixed top-0 left-0 w-full bg-white p-4 flex items-center relative ${TailwindStatics.MINIMUM_WIDTH_200}`}>
      <BackButton />
      <h1 className={`text-black font-sans font-semibold mx-auto ${TailwindStatics.BIG_TEXT}`}>{title}</h1> 
    </div>
  );
};

export default TopNavigator;