const IconPaths = Object.freeze({
    TOPNAVIGATOR_BACK: "/icons/top-navigator/back@2x.png",
    CONTAINER_CATEGORY: "/icons/conent-container-box/category@2x.png",
    CONTAINER_BOOK_OPEN: "/icons/conent-container-box/book-open@2x.png",
    CONTAINER_MICROPHONE_BLACK: "/icons/conent-container-box/microphone-black@2x.png",
    PRIMARY_BUTTON_MICROPHONE_WHITE: "/icons/primary-button/microphone-white@2x.png",
    PRIMARY_BUTTON_CLOCK_PURPLE: "icons/primary-button/clock-purple@2x.png",
    PRIMARY_BUTTON_CLOCK_GREEN: "icons/primary-button/clock-green@2x.png",
    PRIMARY_ARROW_RIGHT: "icons/primary-button/arrow-right@2x.png",
    LOADING_VERIFIED: "icons/loading/verified@3x.png",
    ICON_TEXT_CLIPBOARD: "icons/icon-text/clipboard@2x.png",
    ICON_TEXT_VIDEO_CAMERA: "icons/icon-text/video-camera@2x.png",
    CUSTOM_AUDIO_PLAYER_PLAY: "icons/custom-audio-player/play@3x.png",
    CUSTOM_AUDIO_PLAYER_PAUSE: "icons/custom-audio-player/pause@3x.png",
    CUSTOM_AUDIO_PLAYER_PROGRESS_CIRCLE: "icons/custom-audio-player/circle@2x.png"
  });

  const LoadingStatics = Object.freeze({
    LOADING_TEXT: "답변 시간이 종료되었습니다. 음성파일을 분석중입니다. 약 10초 소요됩니다.",
    LOADING_PRIMARY: "결과 확인하기"
  });

  const PracticeStatics = Object.freeze({
    PROMPT_PRIMARY: "tap to start",
    PRACTICE_TERTIARY: "PREPARING",
    RECORDING_TERTIARY: "SPEAKING",
    START_RECORDING: "Start Recording",
    STOP_RECORDING: "Stop Recording"
  });

  const ResultStatics = Object.freeze({
    RESULT_OVERALL: "Overall Feedback",
    RESULT_RECAP: "Session Recap"
  });

  const Part1Statics = Object.freeze({
    TOPNAVIGATOR_STRING: "Read the Passasge",
    DESCRIPTION_LINE1: "In this part of the test, you will read aloud the text on the screen.",
    DESCRIPTION_LINE2: "You will have 45 seconds to prepare. Then you will have 45 seconds to read the text aloud."
  });

  const TailwindStatics = Object.freeze({
    BIG_TEXT_SIZE: "text-xl sm:text-lg md:text-xl lg:text-2xl",
    SMALL_TEXT_SIZE: "text-sm sm:text-sm md:text-base lg:text-lg",
    MINIMUM_WIDTH_200: "min-w-[200px]",
    MINIMUM_WIDTH_100: "min-w-[100px]"
  })

  const ColorStatics = Object.freeze({
    MAIN_GREEN: "#009963",
    MAIN_YELLOW: "#F5F0E5",
    BASIC_WHITE: "#FFFFFF",
    BASIC_BLACK: "#020617",
    RED_700: "#b91c1c"
  })

  export {IconPaths, PracticeStatics, ResultStatics, LoadingStatics, Part1Statics, TailwindStatics, ColorStatics};
  