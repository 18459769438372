import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios to make API calls
import { useAuth } from '../context/AuthProvider';
import { HomeIcon } from '@heroicons/react/24/outline';

const Part3TemplatePractice = () => {
  const navigate = useNavigate();
  const { user } = useAuth(); // Get the authenticated user

  // State to hold progress for each section
  const [easyProgress, setEasyProgress] = useState({ completed: 0, total: 0 });
  const [interProgress, setInterProgress] = useState({ completed: 0, total: 0 });
  const [diffProgress, setDiffProgress] = useState({ completed: 0, total: 0 });


  // Sections array
  const sections = [
    { title: '기본 만능 문장', path: '/part3-template-practice-easy', id: 'easy' },
    { title: '중급 만능 문장', path: '/part3-template-practice-inter', id: 'inter' },
    { title: '실전 연습 문장', path: '/part3-template-practice-diff', id: 'diff' },
  ];

  // Fetch progress for each section
  useEffect(() => {
    const fetchProgress = async (fileName, setProgress) => {
      if (user) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-template-progress/${user.user_id}`, {
            params: { file_name: fileName }
          });
          setProgress({
            completed: response.data.completed,
            total: response.data.total,
          });
        } catch (error) {
          console.error(`Error fetching progress for ${fileName}:`, error);
        }
      }
    };

    fetchProgress('part3_template_easy.json', setEasyProgress);
    fetchProgress('part3_template_inter.json', setInterProgress);
    fetchProgress('part3_template_diff.json', setDiffProgress);

  }, [user]);

  return (
    <div className="min-h-screen p-6 flex flex-col items-center">
      {/* Home Icon */}
      <div className="w-full max-w-lg flex justify-start mb-4">
        <button onClick={() => navigate('/')}>
          <HomeIcon className="w-6 h-6 text-gray-600" />
        </button>
      </div>

      <h2 className="text-2xl font-semibold mb-8">파트 3 빈출 표현 100</h2>
      <p className="text-base lg:text-lg mb-2">
        파트 3 빈출 표현을 직접 녹음하고 피드백을 확인하며 연습해주세요. 
      </p>
      <p className="text-base lg:text-lg mb-8">
        실전에서 바로 나올 수 있도록 반복적으로 연습하고 <strong>새로운 단어 및 표현은 외워주세요.</strong>
      </p>

      <div className="w-full max-w-lg space-y-6">
        {sections.map((section, index) => (
          <div key={index} className="flex justify-between items-center p-4 border rounded-lg" style={{ borderColor: '#E8DECF' }}>
            {/* Left side: Title and progress */}
            <div className="flex flex-col">
              <p className="text-base lg:text-lg">{section.title}</p>
              {section.id === 'easy' ? (
                <p className="text-sm text-gray-500">{easyProgress.completed}/{easyProgress.total} 문제</p>
              ) : section.id === 'inter' ? (
                <p className="text-sm text-gray-500">{interProgress.completed}/{interProgress.total} 문제</p>
              ) : section.id === 'diff' ? (
                <p className="text-sm text-gray-500">{diffProgress.completed}/{diffProgress.total} 문제</p>
              )  : (
                <p className="text-sm text-gray-500">0/20 문제</p>
              )}
            </div>

            {/* Right side: Start button */}
            <button
              className="ml-auto px-6 py-2 rounded-lg text-white"
              style={{ backgroundColor: '#019863' }}
              onClick={() => navigate(section.path)}
            >
              Start
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Part3TemplatePractice;