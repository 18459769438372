import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import RecordButton from '../components/RecordButton';
import { SparklesIcon, HomeIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const PracticePart3 = () => {
  const { user } = useAuth();
  const [narrator, setNarrator] = useState('');
  const [question, setQuestion] = useState('');
  const [questionId, setQuestionId] = useState('');
  const [progress, setProgress] = useState({ completed: 0, total: 54 });
  const [completedQuestions, setCompletedQuestions] = useState([]);
  const [timer, setTimer] = useState(30); // Initial preparation time of 10 seconds
  const [recording, setRecording] = useState(false);
  const [recordingCompleted, setRecordingCompleted] = useState(false);
  const [transcription, setTranscription] = useState('');
  const [aiFeedback, setAiFeedback] = useState('');
  const [activeTab, setActiveTab] = useState('transcription');
  

  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const timerRef = useRef(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  // Fetch the user's progress and the first question
  useEffect(() => {
    if (user) {
      mixpanel.track('Part 3 Practice Started', { user_id: user.user_id });

      axios.get(`${API_BASE_URL}/api/get-part3-progress/${user.user_id}`)
        .then((response) => {
          const { completed, total, completedQuestions } = response.data;
          setProgress({ completed, total });
          setCompletedQuestions(completedQuestions);

          const nextQuestionId = completed === 0 ? 'p3_i_001' : `p3_i_${String(completed + 1).padStart(3, '0')}`;
          fetchNextQuestion(nextQuestionId);
      
        })
        .catch(error => {
          console.error("Error fetching the progress and question!", error);
        });
    }
  }, [user, API_BASE_URL]);

  useEffect(() => {
    // Automatically start recording when preparation timer hits 0 and it's not recording yet
    if (timer === 0 && !recording && !recordingCompleted) {
      startRecordingTimer();  // Automatically start the recording
    }
  
    // Automatically stop recording when the recording timer hits 0
    if (timer === 0 && recording) {
      stopRecording();  // Automatically stop the recording
    }
  }, [timer, recording, recordingCompleted]);

  const fetchNextQuestion = (nextQuestionId) => {
    axios.get(`${API_BASE_URL}/api/get-part3-question-by-id/${nextQuestionId}`)
      .then((response) => {
        const questionData = response.data;
        setNarrator(questionData.narrator);
        setQuestion(questionData.question.question);
        setQuestionId(nextQuestionId);
        resetStateForNextQuestion();
        // Automatically start the preparation countdown when the question is shown
        startCountdown(30);  // Start the 10-second preparation timer
      })
      .catch(error => {
        console.error("Error fetching the next question!", error);
      });
  };

  const resetStateForNextQuestion = () => {
    clearInterval(timerRef.current);
    setTimer(30);  // Reset preparation timer to 10 seconds
    setRecording(false);
    setRecordingCompleted(false);
    setTranscription('');
    setAiFeedback('');
    setActiveTab('transcription');
  };

  const startCountdown = (duration) => {
    setTimer(duration);
    clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      setTimer(prev => {
        if (prev <= 1) {
          clearInterval(timerRef.current);
          if (recording) {
            stopRecording();  // Automatically stop recording when time runs out
          }
          return 0;
        }
        return prev - 1;
      });
    }, 1500);
  };

  const startRecordingTimer = () => {
    clearInterval(timerRef.current);
    setTimer(30);  // Set recording timer to 30 seconds
    setRecording(true);
    setRecordingCompleted(false);
    startCountdown(15);  // Start the 30-second recording timer
    handleStartRecording();
    mixpanel.track('Part 3 Recording Started', { user_id: user.user_id, question_id: questionId });
  };

  const handleStartRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);
    recordedChunksRef.current = [];

    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        recordedChunksRef.current.push(event.data);
      }
    };

    mediaRecorderRef.current.start();
  };

  const stopRecording = () => {
    clearInterval(timerRef.current);
    setRecording(false);  // Mark recording as stopped
    setTimer(0);  // Reset timer to 0

    handleStopRecording();
    setRecordingCompleted(true);  // Set recording as completed
    mixpanel.track('Part 3 Recording Stopped', { user_id: user.user_id, question_id: questionId });

  };

  const handleStopRecording = () => {
    if (!mediaRecorderRef.current || mediaRecorderRef.current.state === 'inactive') return;

    mediaRecorderRef.current.stop();

    const stream = mediaRecorderRef.current.stream;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());  // Stop all media tracks to remove recording icon
    }

    mediaRecorderRef.current.onstop = async () => {
      const blob = new Blob(recordedChunksRef.current, { type: 'audio/webm' });
      const file = new File([blob], 'recording.webm', { type: 'audio/webm' });

      const formData = new FormData();
      formData.append('file', file);
      console.log("question", question);
      formData.append('model_answer', question);

      try {
        const response = await axios.post(`${API_BASE_URL}/api/part5-speech-to-text`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setTranscription(response.data.transcription);
        setAiFeedback(response.data.ai_feedback);
        setRecordingCompleted(true);
      } catch (error) {
        console.error("Error in transcription!", error);
      }
    };

    mediaRecorderRef.current = null;  // Clear the media recorder reference
  };

  const handleNextQuestion = () => {
    if (user && questionId) {
      axios.post(`${API_BASE_URL}/api/complete-part3-question/${user.user_id}/${questionId}`)
        .then(() => {
          setProgress(prev => ({
            ...prev,
            completed: prev.completed + 1,
          }));
          const nextIdNumber = parseInt(questionId.split('_').pop()) + 1;
          const nextQuestionId = `p3_i_${String(nextIdNumber).padStart(3, '0')}`;
          fetchNextQuestion(nextQuestionId);
          mixpanel.track('Part 3 Question Completed', { user_id: user.user_id, question_id: questionId });

        })
        .catch(error => {
          console.error("Error marking the question as completed!", error);
        });
    }
  };const formatAiFeedback = (feedback) => {
    const parts = feedback.split(/(\d+\.\s|-|\*\*.*?\*\*)/); // Split on numbers, dashes, and bold parts

    return parts.map((part, index) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      }
      if (part.match(/\d+\.\s|-/)) {
        return (
          <>
            <br key={index} />
            {part}
          </>
        );
      }
      return part;
    });
  };

  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
    mixpanel.track('Part 3 Feedback Tab Clicked', { user_id: user.user_id, tab });
  };

  const fetchQuestionById = (id) => {
    const questionId = `p3_i_${String(id).padStart(3, '0')}`;
    fetchNextQuestion(questionId);
  };

  const progressPercentage = (completedQuestions.length / progress.total) * 100;

  const handleDropdownChange = (event) => {
    const selectedQuestionId = parseInt(event.target.value);
    if (selectedQuestionId <= progress.completed) {
      fetchQuestionById(selectedQuestionId);
    }
  };

  const handleHomeClick = () => {
    navigate('/'); // Redirect to the landing page
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-start p-4">
      <div className="w-full max-w-4xl mx-auto"> {/* Add margins for large screens */}
        {/* Top Row: Home Button and Title */}
        <div className="flex justify-between items-center mb-4">
          <button className="text-base sm:text-lg" onClick={handleHomeClick}>
            <HomeIcon className="w-6 h-6" />
          </button>
          <h1 className="text-base sm:text-lg font-bold">Part 3 연습</h1>
          <div className="w-24"></div> {/* Placeholder for alignment */}
        </div>

        {/* Dropdown for Questions */}
        <div className="mb-4">
          <select
            className="border rounded p-2 w-full"
            value={parseInt(questionId.split('_')[2])}  // Correctly extract the current question number
            onChange={handleDropdownChange}
          >
            {Array.from({ length: progress.completed + 1 }, (_, i) => i + 1).map((number) => (
              <option key={number} value={number}>
                Question {number}
              </option>
            ))}
          </select>
        </div>

        {/* Progress Bar */}
        <div className="mb-4">
          <p className="text-base sm:text-lg">진행상황</p>
          <div className="relative w-full h-4 bg-gray-300 rounded">
            <div
              className="absolute h-full bg-[#009963] rounded"
              style={{ width: `${progressPercentage}%` }}
            ></div>

            <div
              className="absolute top-6 text-sm sm:text-base"
              style={{ left: `calc(${progressPercentage}% - 10px)` }} // Adjust the position
            >
              {completedQuestions.length}
            </div>
          </div>

          <div className="flex justify-between mt-2 text-sm sm:text-base">
            <span></span>
            <span>{progress.total}</span>
          </div>
        </div>

        {/* Narrator and Question Display */}
        <div className="mb-4">
          <p className="text-base md:text-xl mb-6"><strong>Narrator:</strong> {narrator}</p>
          <p className="text-base md:text-xl"><strong>Question:</strong> {question}</p>
        </div>

        {/* Timer and Recording Button */}
        <div className="mt-4 flex flex-col items-center">
          <p className="text-lg mb-8">Time left: {timer}초</p>
          <RecordButton
            recording={recording}
            onStart={startRecordingTimer}
            onStop={stopRecording}
            buttonText={recording ? 'Stop Recording' : (recordingCompleted ? 'Re-try' : 'Start Recording')}
          />
        </div>

        {/* Tabs for '나의 답변' and 'AI 피드백' */}
        {transcription && (
          <div className="mt-6">
            <div className="flex justify-between border-b-2 border-[#E5E8EB]">
              <div
                className={`cursor-pointer text-lg w-1/2 flex justify-center ${activeTab === 'transcription' ? 'border-b-4 border-[#009963] text-black' : 'border-none'}`}
                onClick={() => handleTabSwitch('transcription')}
              >
                나의 답변
              </div>
              <div
                className={`cursor-pointer text-lg w-1/2 flex justify-center items-center ${activeTab === 'aiFeedback' ? 'border-b-4 border-[#009963] text-black' : 'border-none'}`}
                onClick={() => handleTabSwitch('aiFeedback')}
              >
                AI 피드백
                <SparklesIcon className="w-5 h-5 ml-2 text-yellow-500" />
              </div>
            </div>

            {/* Tab Content */}
            {activeTab === 'transcription' && (
              <div className="mt-4 p-4 bg-white shadow-lg rounded-lg">
                <p>{transcription}</p>
              </div>
            )}

            {activeTab === 'aiFeedback' && (
              <div className="mt-4 p-4 bg-white shadow-lg rounded-lg">
                <p>{formatAiFeedback(aiFeedback)}</p>
              </div>
            )}

            {/* Next Question Button */}
            <button
              onClick={handleNextQuestion}
              className="px-6 py-3 mt-6 w-full rounded-lg bg-[#F5F0E5] text-black text-base sm:text-lg"
            >
              Next Question
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PracticePart3;