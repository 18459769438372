import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';


const InstructionTemplate = ({ mocktestId, partNumber }) => {
  const [audioEnded, setAudioEnded] = useState(false);
  const [instructionsText, setInstructionsText] = useState('');
  const audioRef = useRef(null);
  const navigate = useNavigate();

  // Track page load with partNumber
  useEffect(() => {
    mixpanel.track(`Instruction Page Loaded`, {
      part: partNumber,
      mocktestId: mocktestId,
    });
  }, [partNumber, mocktestId]);

  // Get the part-specific title for each part
  const getTitleForPart = (part) => {
    switch (part) {
      case 1:
        return "Question 1-2: Read a text aloud";
      case 2:
        return "Question 3-4: Describe a picture";
      case 3:
        return "Question 5-7: Respond to questions";
      case 4:
        return "Question 8-10: Respond to questions using information provided";
      case 5:
        return "Question 11: Express an opinion";
      default:
        return "Instructions";
    }
  };

  useEffect(() => {
    // Fetch the instruction text based on mocktestId and partNumber
    const fetchInstructions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/mocktest${mocktestId}`);
        const instructionText = getInstructionsForPart(partNumber);  // Fetch based on the part number
        setInstructionsText(instructionText);
      } catch (error) {
        console.error('Error fetching instruction data:', error);
      }
    };

    fetchInstructions();
  }, [mocktestId, partNumber]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.play().catch((err) => console.error("Audio playback error:", err));
      audioRef.current.onended = () => setAudioEnded(true);
    }
  }, []);

  const handleStartPart = () => {
    if (audioRef.current) audioRef.current.pause();

    // Updated navigation logic
    if (partNumber === 3) {
      navigate(`/mocktest${mocktestId}/part3-narrator/0`);
    } else if (partNumber === 4) {
      navigate(`/mocktest${mocktestId}/part4-tableprep`);
    } else {
      navigate(`/mocktest${mocktestId}/part${partNumber}-problems`);
    }
  };

  const getInstructionsForPart = (part) => {
    switch (part) {
      case 1:
        return "<strong>Directions:</strong> In this part of the test, you will read aloud the text on the screen. You will have 45 seconds to prepare. Then you will have 45 seconds to read the text aloud.";
      case 2:
        return "<strong>Directions:</strong> In this part of the test, you will describe the picture on your screen in as much detail as you can. You will have 45 seconds to prepare your response. Then you will have 30 seconds to speak about the picture.";
      case 3:
        return "<strong>Directions:</strong> In this part of the test, you will answer three questions. You will have three seconds to prepare after you hear each question. You will have 15 seconds to respond to Questions 5 and 6, and 30 seconds to respond to Question 7.";
      case 4:
        return "<strong>Directions:</strong> In this part of the test, you will answer three questions based on the information provided. You will have 45 seconds to read the information before the questions begin. You will have three seconds to prepare and 15 seconds to respond to Questions 8 and 9. You will hear Question 10 two times. You will have three seconds to prepare and 30 seconds to respond to Question 10.";
      case 5:
        return "<strong>Directions:</strong> In this part of the test, you will give your opinion about a specific topic. Be sure to say as much as you can in the time allowed. You will have 45 seconds to prepare. Then you will have 60 seconds to speak.";
      default:
        return "";
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4 lg:p-8">
      {/* Title at the top based on partNumber */}
      <h1 className="text-xl lg:text-2xl font-bold text-center mb-6 lg:mb-8 mt-0">
        {getTitleForPart(partNumber)}
      </h1>

      {/* Instruction Text */}
      <div className="bg-white p-6 lg:p-8 rounded-lg w-full max-w-4xl mx-auto mb-6 lg:mb-8">
        <p
          className="text-base lg:text-lg"
          dangerouslySetInnerHTML={{ __html: instructionsText }} // Use dangerouslySetInnerHTML to render the bold "Directions"
        />
      </div>

      {/* Audio Element */}
      <audio ref={audioRef} src={`/recordings/part${partNumber}_description.mp3`} />

      {/* Start Button */}
      <button
        onClick={handleStartPart}
        disabled={!audioEnded}
        className={`px-6 py-3 rounded-lg text-white text-base lg:text-lg w-full max-w-4xl mx-auto 
                    ${audioEnded ? 'bg-blue-600' : 'bg-gray-400 cursor-not-allowed'}`}
      >
        Start Part {partNumber}
      </button>
    </div>
  );
};

export default InstructionTemplate;