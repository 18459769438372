import React, { useEffect } from 'react';
import { Routes, Route, useLocation, BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthProvider';  // Import AuthProvider
import mixpanel from 'mixpanel-browser';  // Import Mixpanel
import LandingPage from './pages/LandingPage';
import PracticePart2 from './pages/PracticePart2';
import PracticePart1 from './pages/PracticePart1';
import PricingPage from './pages/PricingPage';
import PracticePart3 from './pages/PracticePart3';
import PracticePart4 from './pages/PracticePart4';
import PracticePart5 from './pages/PracticePart5';
import LoginPage from './pages/LoginPage'; 
import Part1Description from './components/Part1Description';
import Part2Description from './components/Part2Description';
import Part4Description from './components/Part4Description';
import Part5Description from './components/Part5Description';
import Part3Description from './components/Part3Description';
import MockTest1Legacy from './pages/MockTest1Legacy';
import MockTest1 from './pages/MockTest1';
import SignUpPage from './pages/SignUpPage';
import PrivateRoute from './components/PrivateRoute';  // Import PrivateRoute
import MockTestDescription from './components/MockTestDescription';
import Part2TemplatePractice from './components/Part2TemplatePractice';
import Part2TemplatePracticeLocation from './pages/Part2TemplatePracticeLocation';
import Completed from './pages/Completed';
import Part2TemplatePracticeAction from './pages/Part2TemplatePracticeAction';
import Part2TemplatePracticePeople from './pages/Part2TemplatePracticePeople';
import Part2TemplatePracticeSurrounding from './pages/Part2TemplatePracticeSurrounding';
import Part5TemplatePractice from './components/Part5TemplatePractice';
import Part5TemplatePracticeWork from './pages/Part5TemplatePracticeWork';
import Part5TemplatePracticeTechnology from './pages/Part5TemplatePracticeTechnology';
import Part5TemplatePracticeTravel from './pages/Part5TemplatePracticeTravel';
import Part5TemplatePracticeHealth from './pages/Part5TemplatePracticeHealth';
import Part5TemplatePracticeMoney from './pages/Part5TemplatePracticeMoney';
import Part5TemplatePracticeLearning from './pages/Part5TemplatePracticeLearning';
import InstructionTemplate from './components/mocktest/InstructionTemplate';
import MockTestPart1Problems from './components/mocktest/MockTestPart1Problems';
import MockTestPart2Problems from './components/mocktest/MockTestPart2Problems';
import MockTestPart3Narrator from './components/mocktest/MockTestPart3Narrator';
import MockTestPart3Problems from './components/mocktest/MockTestPart3Problems';
import MockTestPart4Narrator from './components/mocktest/MockTestPart4Narrator';
import MockTestPart4Problems from './components/mocktest/MockTestPart4Problems';
import MockTestPart4TablePrep from './components/mocktest/MockTestPart4TablePrep';
import MockTestPart5Problems from './components/mocktest/MockTestPart5Problems';
import MockTestCompleted from './pages/MockTestCompleted';
import DraftMockResult from './pages/DraftMockResult';
import TemplatePractice from './pages/TemplatePractice';
import Part3TemplatePractice from './components/Part3TemplatePractice';
import Part3TemplatePracticeEasy from './pages/Part3TemplatePracticeEasy';
import Part3TemplatePracticeInter from './pages/Part3TemplatePracticeInter';
import Part3TemplatePracticeDiff from './pages/Part3TemplatePracticeDiff';


function App() {

  useEffect(() => {
    // Initialize Mixpanel
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
      debug: true,
    });
  }, []);

  return (
    <div className="App">
      {/* <Router> */}
      
        <AuthProvider>
          {/* <MixpanelTracker /> */}
          <Routes>
            {/* Public routes */}
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/" element={<LandingPage />} />

            {/* Protected routes */}
            <Route element={<PrivateRoute />}>
              <Route path="/part1-description" element={<Part1Description />} />
              <Route path="/practice-part1" element={<PracticePart1 />} />
              <Route path="/part2-description" element={<Part2Description />} />
              <Route path="/practice-part2" element={<PracticePart2 />} />
              <Route path="/practice-part3" element={<PracticePart3 />} />
              <Route path="/part3-description" element={<Part3Description />} />
              <Route path="/practice-part4" element={<PracticePart4 />} />
              <Route path="/part4-description" element={<Part4Description />} />
              <Route path="/practice-part5" element={<PracticePart5 />} />
              <Route path="/part5-description" element={<Part5Description />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/mocktest-description" element={<MockTestDescription />} />
              <Route path="/part2-template-practice" element={<Part2TemplatePractice />} />
              <Route path="/part2-template-practice-location" element={<Part2TemplatePracticeLocation />} />
              <Route path="/completed" element={<Completed />} />
              <Route path="/part2-template-practice-action" element={<Part2TemplatePracticeAction />} />
              <Route path="/part2-template-practice-people" element={<Part2TemplatePracticePeople />} />
              <Route path="/part2-template-practice-surrounding" element={<Part2TemplatePracticeSurrounding />} />
              <Route path="/part5-template-practice" element={<Part5TemplatePractice />} />
              <Route path='/part5-template-practice-work' element={<Part5TemplatePracticeWork />} />
              <Route path='/part5-template-practice-technology' element={<Part5TemplatePracticeTechnology />} />
              <Route path='/part5-template-practice-travel' element={<Part5TemplatePracticeTravel />} />
              <Route path='/part5-template-practice-health' element={<Part5TemplatePracticeHealth />} />
              <Route path='/part5-template-practice-money' element={<Part5TemplatePracticeMoney />} />
              <Route path='/part5-template-practice-learning' element={<Part5TemplatePracticeLearning />} />
              <Route path="/template-practice" element={<TemplatePractice />} />
              <Route path='/part3-template-practice' element={<Part3TemplatePractice />} />
              <Route path='/part3-template-practice-easy' element={<Part3TemplatePracticeEasy />} />
              <Route path='/part3-template-practice-inter' element={<Part3TemplatePracticeInter />} />
              <Route path='/part3-template-practice-diff' element={<Part3TemplatePracticeDiff />} />
              {/* Route for MockTest1 */}
                <Route path="/mocktest1" element={<MockTest1 />} />

                {/* Part 1 */}
                <Route path="/mocktest1/part1-instruction" element={<InstructionTemplate mocktestId={1} partNumber={1} />} />
                <Route path="/mocktest1/part1-problems" element={<MockTestPart1Problems mocktestId={1} />} />

                {/* Part 2 */}
                <Route path="/mocktest1/part2-instruction" element={<InstructionTemplate mocktestId={1} partNumber={2} />} />
                <Route path="/mocktest1/part2-problems" element={<MockTestPart2Problems mocktestId={1} />} />

                {/* Part 3 */}
                <Route path="/mocktest1/part3-instruction" element={<InstructionTemplate mocktestId={1} partNumber={3} />} />
                <Route path="/mocktest1/part3-narrator/:questionIndex" element={<MockTestPart3Narrator mocktestId={1} />} />
                <Route path="/mocktest1/part3-problems/:questionIndex" element={<MockTestPart3Problems mocktestId={1} />} />

                {/* Part 4 */}
                <Route path="/mocktest1/part4-instruction" element={<InstructionTemplate mocktestId={1} partNumber={4} />} />
                <Route path="/mocktest1/part4-tableprep" element={<MockTestPart4TablePrep mocktestId={1} />} />
                <Route path="/mocktest1/part4-narrator/:questionIndex" element={<MockTestPart4Narrator mocktestId={1} />} />
                <Route path="/mocktest1/part4-problems/:questionIndex" element={<MockTestPart4Problems mocktestId={1} />} />

                {/* Part 5 */}
                <Route path="/mocktest1/part5-instruction" element={<InstructionTemplate mocktestId={1} partNumber={5} />} />
                <Route path="/mocktest1/part5-problems" element={<MockTestPart5Problems mocktestId={1} />} />

                {/* Mock test completed */}
                <Route path="/mocktest1/completed" element={<MockTestCompleted mocktestId={1} />} />

                <Route path="/draft-mocktest-result" element={<DraftMockResult />} />
            </Route>
          </Routes>
        </AuthProvider>
      {/* </Router> */}
    </div>
  );
}

export default App;