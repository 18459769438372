import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InstructionTemplate from '../components/mocktest/InstructionTemplate';

const MockTest1 = () => {
  const [currentPart, setCurrentPart] = useState(1);  // Start with part 1
  const navigate = useNavigate();

  const handleNextPart = () => {
    // Move to the next part based on the current part
    if (currentPart < 5) {
      setCurrentPart(currentPart + 1);
    } else {
      navigate(`/mocktest1/completed`);  // Navigate to the completion page
    }
  };

  return (
    <div>
      {/* Dynamically render the instruction template based on the current part */}
      <InstructionTemplate mocktestId={1} partNumber={currentPart} />
      {/* <button
        onClick={handleNextPart}
        className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg"
      >
        Next Part
      </button> */}
    </div>
  );
};

export default MockTest1;