import React from 'react';

// Make it pretty
const RecordButton = ({ recording, onStart, onStop }) => {
  return (
    <button
      onClick={recording ? onStop : onStart}
      className={`px-6 py-3 rounded-lg border border-solid shadow-md text-white
        ${recording ? 'bg-red-600 hover:bg-red-700 border-red-700' : 'bg-green-600 hover:bg-green-700 border-green-700'}
        text-base md:text-lg lg:text-xl  // Responsive text sizes
      `}
    >
      {recording ? 'Stop Recording' : 'Start Recording'}
    </button>
  );
};

export default RecordButton;