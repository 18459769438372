import React, { useState, useEffect } from 'react';
import { IconPaths, TailwindStatics } from '../statics';

const CustomAudioPlayer = ({audioBlob, duration, id }) => {
  const [audioURL, setAudioURL] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);

  const playIcon = IconPaths.CUSTOM_AUDIO_PLAYER_PLAY;
  const pauseIcon = IconPaths.CUSTOM_AUDIO_PLAYER_PAUSE;
  const audioId = `audio-player-${id}`;

  useEffect(() => {
    if (audioBlob) {
      const url = URL.createObjectURL(audioBlob);
      setAudioURL(url);

      return () => URL.revokeObjectURL(url);
    }
  }, [audioBlob]);


  useEffect(() => {
    const audioElement = document.getElementById(audioId);
    if (audioElement) {
      const onTimeUpdate = () => {
        setCurrentTime(audioElement.currentTime);
      };

      const handleEnded = () => {
        const audioElement = document.getElementById(audioId);
        setIsPlaying(false);
        audioElement.currentTime = 0;
      };

      audioElement.addEventListener('timeupdate', onTimeUpdate);
      audioElement.addEventListener('ended', handleEnded);

      return () => {
        audioElement.removeEventListener('timeupdate', onTimeUpdate);
        audioElement.removeEventListener('ended', handleEnded);
      };
    }
  }, [audioURL]);

  const handlePlay = () => {
    const audioElement = document.getElementById(audioId);
    if (audioElement) {
      audioElement.play();
      setIsPlaying(true);
    }
  };

  const handlePause = () => {
    const audioElement = document.getElementById(audioId);
    if (audioElement) {
      audioElement.pause();
      setIsPlaying(false);
    }
  };

  const progress = currentTime <= duration ? (currentTime / duration) * 100 : 100;

  return (
    <div className={`flex flex-col items-center bg-transparent max-w-md w-full mt-5 mb-5 ${TailwindStatics.MINIMUM_WIDTH_100}`}>
      <audio id={audioId} src={audioURL} preload="metadata"/>
      
      <div className="flex items-center bg-transparent w-full">
        <button
            onClick={isPlaying ? handlePause : handlePlay}
            className="relative z-10"
            style={{ cursor: 'pointer' }} 
          >
            <img
              src={isPlaying ? pauseIcon : playIcon}
              alt={isPlaying ? 'Pause' : 'Play'}
              className="object-contain" 
              style={{ maxHeight: '32px', maxWidth: '32px' }} 
            />
          </button>
        
        <div className="flex-1 h-2 bg-gray-200 rounded ml-4 relative">
          <div
            className="h-full bg-black rounded"
            style={{ width: `${progress}%` }}
          />
          <img
            src={IconPaths.CUSTOM_AUDIO_PLAYER_PROGRESS_CIRCLE}
            alt="Progress"
            className="absolute transform -translate-x-1/2 -translate-y-1/2"
            style={{
              left: `${progress}%`, 
              top: '50%', 
              width: '25px',  
              height: '25px',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomAudioPlayer;
