import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const MockTestPart4Narrator = ({ mocktestId }) => {
  const audioRef = useRef(null);
  const [tableData, setTableData] = useState(null);
  const [notes, setNotes] = useState('');  // New state hold notes
  const [title, setTitle] = useState('');  // New state to hold table title
  const [question, setQuestion] = useState('');
  const navigate = useNavigate();
  const { questionIndex = 0 } = useParams();  // Get questionIndex from URL params
  const [subTitle, setSubTitle] = useState('');  // New state to hold table subtitle

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/mocktest${mocktestId}`);
        setTableData(response.data.part4.table);  // Set table data
        setTitle(response.data.part4.table.title);  // Set table title
        setSubTitle(response.data.part4.table.subtitle);  // Set table subtitle
        setNotes(response.data.part4.notes);        // Set notes
        setQuestion(response.data.part4.questions[questionIndex].text);  // Set question text
      } catch (error) {
        console.error('Error fetching narrator data:', error);
      }
    };
    fetchData();
  }, [mocktestId, questionIndex]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.play().catch((err) => console.error("Audio playback error:", err));
      audioRef.current.onended = () => navigate(`/mocktest${mocktestId}/part4-problems/${questionIndex}`);
    }
  }, [navigate, mocktestId, questionIndex]);
  console.log("questionIndex", questionIndex);

  return (
    <div className="min-h-screen bg-white flex flex-col items-center justify-start">
      {/* Title with gray background */}
      <h1 className="text-xl lg:text-2xl font-bold text-center mb-6 bg-gray-300 w-full py-4">
        Questions {parseInt(questionIndex, 10) + 8} of 11
      </h1>
  
      {/* Main content area */}
      <div className="bg-white rounded-lg p-6 lg:p-8 w-full max-w-3xl mb-6">
        {/* Display table title if present */}
        {title && (
          <h2 className="text-lg lg:text-xl font-semibold mb-4 text-center">{title}</h2>
        )}
        {/* Display table subtitle if present */}
        {subTitle && (
          <h3 className="text-base lg:text-lg font-semibold mb-4 text-center">{subTitle}</h3>
        )}
        {/* Responsive table container */}
        <div className="w-full overflow-x-auto">
          {/* Display the table with fixed layout */}
          {tableData && (
            <table className="table-auto w-full border-collapse border border-gray-300 table-fixed">
              <thead>
                <tr>
                  {tableData.headers.map((header, index) => (
                    <th key={index} className="border border-gray-300 p-2 text-center text-xs md:text-base lg:text-lg">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData.rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex} className="border border-gray-300 p-2 text-center whitespace-normal">
                      {/* Check if the row contains strikethrough content */}
                      {cell.includes("~~") ? (
                        <del>{cell.replace(/~~/g, '')}</del>  // Remove ~~ and wrap with <del> tag
                      ) : (
                        cell  // Normal rendering if no strikethrough
                      )}
                    </td>
                  ))}
                </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
  
        {/* Display notes if present */}
        {notes && (
          <p className="text-base md:text-lg lg:text-xl italic text-gray-700 mb-6">
            {notes}
          </p>
        )}
  
        {/* Display the narrator question */}
        <p className="text-base md:text-lg lg:text-xl text-gray-700 mt-6 mb-6">
          {question}
        </p>
  
        {/* Audio player */}
        <audio ref={audioRef} src={`/recordings/mocktest${mocktestId}_part4_q${parseInt(questionIndex, 10) + 1}.mp3`} />
      </div>
    </div>
  );
};

export default MockTestPart4Narrator;