import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const MockTestPart4TablePrep = ({ mocktestId }) => {
  const [tableData, setTableData] = useState(null);
  const [notes, setNotes] = useState('');  // State to hold the notes
  const [timer, setTimer] = useState(45);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch table data for Part 4
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/mocktest${mocktestId}`)
      .then(response => {
        const part4Data = response.data.part4;
        setTableData(part4Data.table);
        setNotes(part4Data.notes);  // Set the notes from the response
      })
      .catch(error => console.error("Error fetching table data:", error));

    // Start preparation countdown
    const countdown = setInterval(() => {
      setTimer(prev => {
        if (prev === 1) {
          clearInterval(countdown);
          navigate(`/mocktest${mocktestId}/part4-narrator/0`);
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, [mocktestId, navigate]);

  return (
    <div className="min-h-screen bg-white flex flex-col items-center justify-start">
      {/* Title with gray background */}
      <h1 className="text-xl lg:text-2xl font-bold text-center mb-6 bg-gray-300 w-full py-4">
        Part 4
      </h1>

      {/* Main content area */}
      <div className="bg-white rounded-lg p-6 lg:p-8 w-full max-w-3xl mb-6">
        {/* Display table title if present */}
        {tableData && (
          <>
            <h2 className="text-lg lg:text-xl font-semibold mb-4 text-center">{tableData.title}</h2> {/* Table Title */}
            <h3 className="text-base lg:text-lg font-semibold mb-4 text-center">{tableData.subtitle}</h3>  {/* Table Subtitle */}
            {/* Responsive table container */}
            <div className="w-full overflow-x-auto">
              {/* Display the table with fixed layout */}
              <table className="table-auto w-full border-collapse border border-gray-300 table-fixed text-sm">
                <thead>
                  <tr>
                    {tableData.headers.map((header, index) => (
                      <th key={index} className="border border-gray-300 p-2 text-center">
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData.rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex} className="border border-gray-300 p-2 text-center whitespace-normal">
                        {/* Check if the row contains strikethrough content */}
                        {cell.includes("~~") ? (
                          <del>{cell.replace(/~~/g, '')}</del>  // Remove ~~ and wrap with <del> tag
                        ) : (
                          cell  // Normal rendering if no strikethrough
                        )}
                      </td>
                    ))}
                  </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Display the notes */}
            {notes && (
              <p className="text-sm md:text-base lg:text-lg italic text-gray-700 mt-4 mb-6">
                {notes}
              </p>
            )}
          </>
        )}

        {/* Preparation timer */}
        <p className="text-base lg:text-lg text-gray-800 mt-6 mb-6 text-center">
          Preparation Time: {timer} seconds
        </p>

        {/* Start question button */}
        <div className="flex justify-center">
          <button
            onClick={() => navigate(`/mocktest${mocktestId}/part4-narrator/0`)}
            className="mt-4 px-6 py-3 bg-blue-600 text-white rounded-lg text-base lg:text-lg"
          >
            Start Question 8
          </button>
        </div>
      </div>
    </div>
  );
};

export default MockTestPart4TablePrep;