import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const Part4Description = () => {
  const navigate = useNavigate();
  const [showTips, setShowTips] = useState(false);  // State to toggle tips visibility
  const audioRef = useRef(new Audio(`${process.env.REACT_APP_S3_BASE_URL}/recordings/part4-practice-question.mp3`));  // Part 2 audio

  // Start the practice and stop the audio
  const startPractice = () => {
    if (audioRef.current) {
      audioRef.current.pause();  // Stop the audio
    }
    navigate('/practice-part4');  // Navigate to Part 2 practice page
  };

  // Play audio on component mount
  useEffect(() => {
    try {
      // Check if Mixpanel is initialized, and track the event
      if (mixpanel && typeof mixpanel.track === 'function') {
        mixpanel.track('Part 4 Description Page Loaded');
      } else {
        throw new Error('Mixpanel not initialized');
      }

      // Play the audio
      if (audioRef.current) {
        audioRef.current.play().catch(err => console.error('Audio failed to play:', err));
      }

    } catch (error) {
      console.error('Error with Mixpanel or audio:', error);
      // Redirect to home page if Mixpanel is not initialized
      navigate('/');
    }

    return () => {
      // Stop the audio when the component unmounts
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, [navigate]);

  return (
    <div className="min-h-screen bg-neutral-50 flex flex-col items-center justify-center p-4">
      <h1 className="text-2xl font-bold text-center mb-8">
        Part 4: Respond to Questions Using Information Provided
      </h1>
  
      <h2 className="text-base lg:text-lg mb-8 w-full max-w-4xl mx-auto">
        In this part of the practice, you will answer three questions based on the information provided. You will have 45 seconds to read the information and prepare your response. You will have 15 seconds to respond to the first two questions and 30 seconds for the third question.
      </h2>
  
      {/* Button to show/hide tips */}
      <button
        className="px-6 py-3 rounded-lg bg-[#F5F0E5] text-black text-lg w-full max-w-4xl mx-auto mb-4"
        onClick={() => setShowTips(!showTips)}
      >
        Tips & Part 4 템플릿 공부하기
      </button>
  
      {/* Tips and Template Section */}
    {showTips && (
      <div className="w-full max-w-4xl mx-auto space-y-6">

        {/* Tip Box A */}
        <div className="bg-white p-6 rounded-lg border border-[#A1824A]">
          <p className="text-base lg:text-lg mb-4">A. Tips:</p>
          <p className="text-base lg:text-lg mb-4">
            파트 4는 <span className="bg-gray-200">표의 정보</span>를 기반으로 질문에 대답하는 문제입니다. 45초 동안 표를 읽고 준비한 후, 질문에 답변하게 됩니다.
          </p>
          <p className="text-base lg:text-lg mb-4">
            첫 번째 질문은 주로 <span className="bg-gray-200">장소</span>와 <span className="bg-gray-200">시간</span>에 대한 질문이 주어집니다.
          </p>
        </div>

        {/* Template Box */}
        <div className="bg-white p-6 rounded-lg border border-[#A1824A] space-y-4">
          <p className="text-base lg:text-lg mb-4">B. 파트 4 템플릿 예시:</p>

          <div className="space-y-4">
            {/* Example A */}
            <p className="text-base lg:text-lg">
              1. 첫 번째 질문은 주로 <span className="underline">시간/장소</span> 관련 문제입니다: The event will begin at (시작 시간) / on (날짜) in/at (장소).
            </p>
          

            {/* 질문 예시 추가 */}
            <p className="text-base lg:text-lg text-gray-700">
              질문 예시: What time does the meeting start and where will it be held?
            </p>
            <p className="text-base lg:text-lg text-gray-700">
              예시 답변: The meeting starts at 10 a.m. in the main conference room on July 15th.
            </p>

            {/* Example C */}
            <p className="text-base lg:text-lg">
              2. 두 번째 질문에서는<span className="underline">잘못되거나 변경된 정보</span>를 알고 있는 화자가 정보를 확인하는 질문이 빈출됩니다.
            </p>
  
            {/* 질문 예시 추가 */}
            <p className="text-base lg:text-lg text-gray-700">
              질문 예시: Can you confirm if the cooking class event is scheduled at 11 a.m.?
            </p>
            <p className="text-base lg:text-lg text-gray-700">
              예시 답변: I'm sorry, but you have the wrong information. The schedule has been rescheduled to 10 a.m.
            </p>

          </div>

          {/* Additional Example */}
          <p className="text-base lg:text-lg">
            3. 세 번째 질문에서는 <span className="underline">상세 정보를 나열</span>하는 문제가 빈출됩니다.
          </p>
          <p className="text-base lg:text-lg text-gray-700">
            예시 질문: Are there any events for the weekend and could you tell me details about them?
          </p>
          <p className="text-base lg:text-lg text-gray-700">
            예시 답변: There are two events for the weekend. The first is a marketing workshop on Saturday that begins at 2 p.m., and the second is a product management seminar on Sunday that begins at 11 a.m.
          </p>
        </div>
      </div>
    )}

    {/* Button to start practice */}
    <button
      className="px-6 py-3 rounded-lg bg-[#009963] text-lg text-white mt-8"
      onClick={startPractice}
    >
      파트 4 연습 시작하기!
    </button>
  </div>
);
};

export default Part4Description;