import React, { useState } from 'react';
import { ColorStatics } from '../statics';

const CustomAlert = ({ message, onCloseButtonText, onClose  }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-2/3 text-center">
        <p className="mb-4">{message}</p>
        <button 
          className="text-white px-4 py-2 rounded"
          onClick={onClose}
          style={{ backgroundColor: ColorStatics.MAIN_GREEN }}
        >
          {onCloseButtonText}
        </button>
      </div>
    </div>
  );
};

export default CustomAlert;