import React, { useState, useRef } from 'react';
import TopNavigator from '../components/TopNavigator';
import PrimaryButton from '../components/PrimaryButtion';
import { ColorStatics } from '../statics';
import { useNavigate, useLocation } from 'react-router-dom';

const Completed = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { part } = location.state || {};

  return (
    <div>
        <div className="relative min-h-screen pt-4">
            <TopNavigator title="Part1 Completed" />
            <div className="min-h-screen flex flex-col items-center justify-center p-4">
                <h1 className="text-2xl font-bold text-center mb-8">
                   🎉 Congratulations 🎉
                </h1>
                <h2 className="text-base lg:text-lg text-center mb-8 w-full max-w-4xl mx-auto">
                    축하드립니다! {part || '해당 part'}의 모든 연습문제를 정복하셨습니다!
                </h2>
                <h2 className="text-base lg:text-lg text-center mb-8 w-full max-w-4xl mx-auto">
                    이전 연습문제들을 다시 복습하거나, 다른 part들의 연습문제를 정복해보세요!
                </h2>
                <div className="mt-5 flex justify-center">
                  <PrimaryButton
                      text="홈으로 가기"
                      textColor = {ColorStatics.BASIC_WHITE}
                      backgroundColor={ColorStatics.MAIN_GREEN}
                      onClick={()=>navigate('/')}
                    />
                  </div>
            </div>
        </div>
    </div>
  );
};

export default Completed;