import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios to make API calls
import { useAuth } from '../context/AuthProvider';
import { HomeIcon } from '@heroicons/react/24/outline';

const Part5TemplatePractice = () => {
  const navigate = useNavigate();
  const { user } = useAuth(); // Get the authenticated user

  // State to hold progress for each section
  const [workProgress, setWorkProgress] = useState({ completed: 0, total: 0 });
  const [technologyProgress, setTechnologyProgress] = useState({ completed: 0, total: 0 });
  const [healthProgress, setHealthProgress] = useState({ completed: 0, total: 0 });
  const [travelProgress, setTravelProgress] = useState({ completed: 0, total: 0 });
  const [moneyProgress, setMoneyProgress] = useState({ completed: 0, total: 0 });
  const [learningProgress, setLearningProgress] = useState({ completed: 0, total: 0 });

  // Sections array
  const sections = [
    { title: '일과 생산성 관련', path: '/part5-template-practice-work', id: 'work' },
    { title: '기술과 혁신 관련', path: '/part5-template-practice-technology', id: 'technology' },
    { title: '건강과 웰빙 관련', path: '/part5-template-practice-health', id: 'health' },
    { title: '여행과 여가 관련', path: '/part5-template-practice-travel', id: 'travel' },
    { title: '돈, 저축, 투자 관련', path: '/part5-template-practice-money', id: 'money' },
    { title: '학습, 교육 관련', path: '/part5-template-practice-learning', id: 'learning' },
  ];

  // Fetch progress for each section
  useEffect(() => {
    const fetchProgress = async (fileName, setProgress) => {
      if (user) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-template-progress/${user.user_id}`, {
            params: { file_name: fileName }
          });
          setProgress({
            completed: response.data.completed,
            total: response.data.total,
          });
        } catch (error) {
          console.error(`Error fetching progress for ${fileName}:`, error);
        }
      }
    };

    fetchProgress('part5_template_work.json', setWorkProgress);
    fetchProgress('part5_template_technology.json', setTechnologyProgress);
    fetchProgress('part5_template_health.json', setHealthProgress);
    fetchProgress('part5_template_travel.json', setTravelProgress);
    fetchProgress('part5_template_money.json', setMoneyProgress);
    fetchProgress('part5_template_learning.json', setLearningProgress);
  }, [user]);

  return (
    <div className="min-h-screen p-6 flex flex-col items-center">
      {/* Home Icon */}
      <div className="w-full max-w-lg flex justify-start mb-4">
        <button onClick={() => navigate('/')}>
          <HomeIcon className="w-6 h-6 text-gray-600" />
        </button>
      </div>

      <h2 className="text-2xl font-semibold mb-8">파트 5 빈출 표현 100</h2>
      <p className="text-base lg:text-lg mb-2">
        파트 5 빈출 표현 100가지를 직접 녹음하고 피드백을 확인하며 연습해주세요. 
      </p>
      <p className="text-base lg:text-lg mb-8">
        실전에서 바로 나올 수 있도록 반복적으로 연습하고 <strong>새로운 단어 및 표현은 외워주세요.</strong>
      </p>

      <div className="w-full max-w-lg space-y-6">
        {sections.map((section, index) => (
          <div key={index} className="flex justify-between items-center p-4 border rounded-lg" style={{ borderColor: '#E8DECF' }}>
            {/* Left side: Title and progress */}
            <div className="flex flex-col">
              <p className="text-base lg:text-lg">{section.title}</p>
              {section.id === 'work' ? (
                <p className="text-sm text-gray-500">{workProgress.completed}/{workProgress.total} 문제</p>
              ) : section.id === 'technology' ? (
                <p className="text-sm text-gray-500">{technologyProgress.completed}/{technologyProgress.total} 문제</p>
              ) : section.id === 'health' ? (
                <p className="text-sm text-gray-500">{healthProgress.completed}/{healthProgress.total} 문제</p>
              ) : section.id === 'travel' ? (
                <p className="text-sm text-gray-500">{travelProgress.completed}/{travelProgress.total} 문제</p>
              ) : section.id === 'money' ? (
                <p className="text-sm text-gray-500">{moneyProgress.completed}/{moneyProgress.total} 문제</p>
              ) : section.id === 'learning' ? (
                <p className="text-sm text-gray-500">{learningProgress.completed}/{learningProgress.total} 문제</p>
              ) : (
                <p className="text-sm text-gray-500">0/20 문제</p>
              )}
            </div>

            {/* Right side: Start button */}
            <button
              className="ml-auto px-6 py-2 rounded-lg text-white"
              style={{ backgroundColor: '#019863' }}
              onClick={() => navigate(section.path)}
            >
              Start
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Part5TemplatePractice;