import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { ContentContainerBox } from '../components/Container';
import CustomAudioPlayer from '../components/CustomAudioPlayer';
import { Doughnut } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import {ColorStatics} from '../statics';

Chart.register(...registerables);

const DoughnutChart = ({ score }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        data: [score, 100 - score],
        backgroundColor: [ColorStatics.MAIN_GREEN, ColorStatics.MAIN_YELLOW],
      },
    ],
  });

  useEffect(() => {
    setChartData({
      labels: [],
      datasets: [
        {
          data: [score, 100 - score],
          backgroundColor: [ColorStatics.MAIN_GREEN, ColorStatics.MAIN_YELLOW],
          animation: {
            duration: 1000, 
            easing: 'easeOutBounce', 
          },
        },
      ],
    });
  }, [score]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    hover: {
      mode: null, 
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="mt-5 mb-5 relative">
      <Doughnut data={chartData} options={options} />
      <div className="absolute inset-0 flex items-center justify-center">
        <span className="text-md font-sans font-semibold">{score}</span>
      </div>
    </div>
  );
};

function ResultWordAudioPlayer({ word }) {
    const [audioBlob, setAudioBlob] = useState(null);
    const [audioDuration, setAudioDuration] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  
    useEffect(() => {
      async function fetchAudio() {
        try {
          const response = await axios.post(`https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`, {
            voice: {
              languageCode: "en-US"
            },
            input: {
              text: word
            },
            audioConfig: {
              audioEncoding: "mp3"
            }
          });
          const base64AudioContent = response.data.audioContent;
          const audioBlob = base64ToBlob(base64AudioContent, 'audio/mp3');
          setAudioBlob(audioBlob);
  
          const audioDuration = await getDurationFromBlob(audioBlob);
          setAudioDuration(audioDuration);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching audio:', error);
          setError(true);
          setLoading(false);
        }
      }
      fetchAudio();
    }, [word]);
  
    function base64ToBlob(base64Data, contentType) {
      const byteCharacters = atob(base64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      return new Blob(byteArrays, { type: contentType });
    }
  
    function getDurationFromBlob(blob) {
      return new Promise((resolve) => {
        const audioURL = URL.createObjectURL(blob);
        const audio = new Audio(audioURL);
        audio.onloadedmetadata = () => {
          resolve(audio.duration);
          URL.revokeObjectURL(audioURL); 
        };
      });
    }
  
    if (loading) {
      return <p className='text-sm text-gray-300'>Loading audio...</p>;
    }
    if (error) {
      return <p className='text-sm text-gray-300'>오디오를 불러오는데 실패했습니다.</p>;
    }
    return (
      <CustomAudioPlayer audioBlob={audioBlob} duration={audioDuration} id={word}/>
    );
  }

const ResultWordList = ({ words }) => {
    const [selectedIdx, setSelectedIdx] = useState(null);
    const firstThreshold = 50;
    const secondThreshold = 80;
    const getColor = (accuracy) => {
      if (accuracy <= firstThreshold) {
        return "#C00F0C";
      } else if (accuracy < secondThreshold) {
        return "#BF6A02";
      } 
    };
    const cursorStyle = (accuracyScore) => {
      return accuracyScore < secondThreshold ? 'pointer' : 'default';
    };
    const handleWordClick = async (wordObj) => {
        if (selectedIdx === wordObj.idx) {
          setSelectedIdx(null);
        } else {
          setSelectedIdx(wordObj.idx); 
        }
    };
  const handleClick = (wordObj) => {
    if (wordObj.accuracy_score <= secondThreshold) {
      handleWordClick(wordObj);
    }
  };
  const SpanWord = ({ 
    onClickFunc = () => {}, 
    score = 100, 
    marginRight = '2px', 
    text }
  ) => {
    return (
      <span
        onClick={onClickFunc} 
        style={{
          color: getColor(score),
          border: `1px dotted ${getColor(score)}`,
          marginRight: marginRight,
          padding: '2px',
          cursor: cursorStyle(score) 
        }}>
        {text}
      </span>
    );
  };
    return(
      <>
        <div className={`mb-2 text-center text-sm font-bold`}>
          <SpanWord 
              score={100} 
              marginRight='15px'
              text='Excellent'
            />
            <SpanWord 
              score={secondThreshold - 1} 
              marginRight='15px'
              text='Good'
            />
            <SpanWord 
              score={firstThreshold - 1} 
              marginRight='0px'
              text='Needs Improvement'
            />
        </div>
        <div className={`mb-10 text-center text-sm`}>
         단어를 클릭해서 정확한 발음을 들어보세요!
        </div>
        <div className="flex flex-wrap">    
          {words.map((wordObj) => (
            <div
              key={wordObj.idx}
              className="mr-2 mb-4 text-center font-sans font-light">
              <SpanWord 
                onClickFunc={() => handleClick(wordObj)} 
                score={wordObj.accuracy_score} 
                text={wordObj.word} 
              />
            {selectedIdx === wordObj.idx &&  (
              <div
                style={{
                   display: 'flex', 
                   flexDirection: 'column', 
                   alignItems: 'center',
                   width: 'fit-content'}}
                >
                  <div>
                    <ResultWordAudioPlayer word={wordObj.word} />
                  </div>
              </div>
             )}
            </div>
          ))}
        </div>
      </>
    )
};

const AnswerContainer = ({ userRecordingAudioBlob, userRecordingDuration, azureAiScore, questionId }) => {
    const [selectedAnswer, setSelectedAnswer] = useState('myAnswer');
    const [correctAudioBlob, setcorrectAudioBlob] = useState(null);
    const [correctAudioDuration, setcorrectAudioDurationn] = useState(0);
  
    useEffect(() => {
      const fetchAudioBlob = async () => {
        const response = await fetch(`/recordings/part1_answers/q${questionId}.mp3`);
        const blob = await response.blob();
        setcorrectAudioBlob(blob);
  
        const audioURL = URL.createObjectURL(blob);
        const audio = new Audio(audioURL);
        audio.onloadedmetadata = () => {
          setcorrectAudioDurationn(audio.duration);
        };
  
        return () => URL.revokeObjectURL(audioURL);
      };
      fetchAudioBlob();
    }, []);
  
    const handleSelect = (answerType) => {
      setSelectedAnswer(answerType);
    };
  
    return (
      <ContentContainerBox>
        <div className="flex items-center">
          <div
            onClick={() => handleSelect('myAnswer')}
            className={`p-4 rounded-lg cursor-pointer transition`}
            style={{
              backgroundColor: selectedAnswer === 'myAnswer' ?ColorStatics.MAIN_YELLOW : '#FFFFFF',
              position: 'relative',
              overflow: 'hidden'
            }}
          >
            <p className="text-md font-sans font-semibold">My Answer</p>
            {selectedAnswer === 'myAnswer' && (
              <div className="absolute -top-1 -left-1 w-full h-full" />
            )}
          </div>
  
          <div
            onClick={() => handleSelect('correctAnswer')}
            className={`p-4 rounded-lg cursor-pointer transition`}
            style={{
              backgroundColor: selectedAnswer === 'correctAnswer' ? ColorStatics.MAIN_YELLOW : '#FFFFFF',
              position: 'relative',
              overflow: 'hidden'
            }}
          >
            <p className="text-md font-sans font-semibold">Correct Answer</p>
            {selectedAnswer === 'correctAnswer' && (
              <div className="absolute -top-1 -left-1 w-full h-full" />
            )}
          </div>
        </div>
  
        {selectedAnswer === 'myAnswer' && userRecordingAudioBlob && (
          <CustomAudioPlayer audioBlob={userRecordingAudioBlob} duration={userRecordingDuration} id={"my-answer"} />
        )}
  
        {selectedAnswer === 'correctAnswer' && correctAudioBlob && (
          <CustomAudioPlayer audioBlob={correctAudioBlob} duration={correctAudioDuration} id={"correct-answer"} />
        )}
  
        <ResultWordList words={azureAiScore.words} />
      </ContentContainerBox>
    );
  };

  export {DoughnutChart, AnswerContainer};