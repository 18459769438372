import {TailwindStatics} from '../statics';

const IconText = ({ 
    iconPath, 
    text, 
    textColor = 'text-black' }) => {
    return (
      <div className={`flex items-center space-x-2 w-full max-w-lg mx-auto ${TailwindStatics.MINIMUM_WIDTH_200}`}>
        <img src={iconPath} alt="Icon" className="w-6 h-6" />
        <span className={`font-sans font-semibold ${textColor} ${TailwindStatics.BIG_TEXT}`}>{text}</span>
      </div>
    );
  };
  
  export default IconText;