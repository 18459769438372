import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios to make API calls
import { useAuth } from '../context/AuthProvider';
import { SparklesIcon, HomeIcon } from '@heroicons/react/24/outline';

const Part2TemplatePractice = () => {
  const navigate = useNavigate();
  const { user } = useAuth(); // Get the authenticated user

  // State to hold progress for the location section
  const [locationProgress, setLocationProgress] = useState({ completed: 0, total: 0 });
  const [actionProgress, setActionProgress] = useState({ completed: 0, total: 0 });
  const [peopleProgress, setPeopleProgress] = useState({ completed: 0, total: 0 });
  const [surroundingProgress, setSurroundingProgress] = useState({ completed: 0, total: 0 });



  const sections = [
    { title: '장소 관련 (난이도: 중하)', path: '/part2-template-practice-location', id: 'location' },
    { title: '동작 관련 (난이도: 중)', path: '/part2-template-practice-action', id: 'action' },
    { title: '인물 묘사 관련 (난이도: 중)', path: '/part2-template-practice-people', id: 'people' },
    { title: '주변 묘사 관련 (난이도: 중상)', path: '/part2-template-practice-surrounding', id: 'surrounding' },
    // { title: '마무리 표현', path: '/part2-template-practice-conclusion', id: 'conclusion' },
  ];

  useEffect(() => {
    // Fetch progress for the location section if user is authenticated
    const fetchLocationProgress = async () => {
      if (user) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-template-progress/${user.user_id}`, {
            params: { file_name: 'part2_template_location.json' }
          });
          setLocationProgress({
            completed: response.data.completed,
            total: response.data.total,
          });
        } catch (error) {
          console.error('Error fetching location progress:', error);
        }
      }
    };

    // Fetch progress for the action section if user is authenticated
    const fetchActionProgress = async () => {
      if (user) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-template-progress/${user.user_id}`, {
            params: { file_name: 'part2_template_action.json' }
          });
          setActionProgress({
            completed: response.data.completed,
            total: response.data.total,
          });
        } catch (error) {
          console.error('Error fetching action progress:', error);
        }
      }
    };

    // Fetch progress for the people section if user is authenticated
    const fetchPeopleProgress = async () => {
      if (user) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-template-progress/${user.user_id}`, {
            params: { file_name: 'part2_template_people.json' }
          });
          setPeopleProgress({
            completed: response.data.completed,
            total: response.data.total,
          });
        } catch (error) {
          console.error('Error fetching people progress:', error);
        }
      }
    };

    // Fetch progress for the surrounding section if user is authenticated
    const fetchSurroundingProgress = async () => {
      if (user) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-template-progress/${user.user_id}`, {
            params: { file_name: 'part2_template_surrounding.json' }
          });
          setSurroundingProgress({
            completed: response.data.completed,
            total: response.data.total,
          });
        } catch (error) {
          console.error('Error fetching surrounding progress:', error);
        }
      }
    };

    fetchLocationProgress();
    fetchActionProgress();
    fetchPeopleProgress();
    fetchSurroundingProgress();
  }, [user]);


  return (
    <div className="min-h-screen  p-6 flex flex-col items-center">
        {/* Home Icon */}
      <div className="w-full max-w-lg flex justify-start mb-4">
        <button onClick={() => navigate('/')}>
          <HomeIcon className="w-6 h-6 text-gray-600" />
        </button>
      </div>

      <h2 className="text-2xl font-semibold mb-8">파트 2 빈출 표현 100</h2>
      <p className="text-base lg:text-lg mb-2">
        파트 2 빈출 표현 100가지를 직접 녹음하고 피드백을 확인하며 연습해주세요. 
      </p>
      <p className="text-base lg:text-lg mb-8">
        실전에서 바로 나올 수 있도록 반복적으로 연습하고 <strong>새로운 단어 및 표현은 외워주세요.</strong>
      </p>

      <div className="w-full max-w-lg space-y-6">
        {sections.map((section, index) => (
          <div key={index} className="flex justify-between items-center p-4 border rounded-lg" style={{ borderColor: '#E8DECF' }}>
            {/* Left side: Title and progress */}
            <div className="flex flex-col">
              <p className="text-base lg:text-lg">{section.title}</p>
              {section.id === 'location' ? (
                <p className="text-sm text-gray-500">{locationProgress.completed}/{locationProgress.total} 문제</p>
              ) : section.id === 'action' ? (
                <p className="text-sm text-gray-500">{actionProgress.completed}/{actionProgress.total} 문제</p>
              ) : section.id === 'people' ? (
                <p className="text-sm text-gray-500">{peopleProgress.completed}/{peopleProgress.total} 문제</p>
              ) : section.id === 'surrounding' ? (
                <p className="text-sm text-gray-500">{surroundingProgress.completed}/{surroundingProgress.total} 문제</p>
              ) : (
                <p className="text-sm text-gray-500">0/20 문제</p> 
              )}
            </div>

            {/* Right side: Start button */}
            <button
              className="ml-auto px-6 py-2 rounded-lg text-white"
              style={{ backgroundColor: '#019863' }}
              onClick={() => navigate(section.path)}
            >
              Start
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Part2TemplatePractice;