// src/components/PricingCard.js
import React from 'react';

const PricingCard = ({ title, price, features }) => {
  return (
    <div className="bg-white border-2 border-gray-300 rounded-lg shadow-lg p-8">
      <h2 className="text-2xl font-bold mb-4 font-['Sansita']">{title}</h2>
      <p className="text-xl font-semibold text-gray-700 mb-6">{price}</p>
      <ul className="space-y-2">
        {features.map((feature, index) => (
          <li key={index} className="text-gray-600 text-sm">
            <span className="inline-block w-2 h-2 bg-gray-600 rounded-full mr-2"></span>
            {feature}
          </li>
        ))}
      </ul>
      <button
        className="mt-8 w-full bg-blue-500 text-white text-lg font-['Sansita'] font-semibold py-3 rounded-full shadow-lg hover:bg-blue-600"
      >
        Choose Plan
      </button>
    </div>
  );
};

export default PricingCard;