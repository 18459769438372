import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';  // Use useAuth to get user info
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import { HomeIcon, ClipboardCheckIcon, DocumentTextIcon, FolderIcon, PencilIcon, AcademicCapIcon, CheckCircleIcon, ChartBarIcon, SpeakerphoneIcon } from '@heroicons/react/24/outline';

const LandingPage = () => {
  const { user, logOut } = useAuth();  // Get user and logOut from context
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedDays, setSelectedDays] = useState(0);  // Default value for countdown days
  const [completedQuestions, setCompletedQuestions] = useState(0);  // For counting completed questions
  const [partProgress, setPartProgress] = useState({});  // For tracking progress on each part
  const [isLogoutVisible, setIsLogoutVisible] = useState(false);  // Control logout button visibility
  const [credit, setCredit] = useState(0);  // Default value for credit
  const navigate = useNavigate();

  const dropdownRef = useRef(null);
  const logoutRef = useRef(null);

  const handleNavigate = (path) => {
    navigate(path);
  }

  // Fetch user-specific landing page data from the backend
  useEffect(() => {
    if (user) {
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/landing-data/${user.user_id}`)
        .then((response) => {
          const { days_until_exam, completed_questions_count, part_progress, credit } = response.data;
          setSelectedDays(days_until_exam);
          setCompletedQuestions(completed_questions_count);
          setPartProgress(part_progress);
          setCredit(credit);
        })
        .catch((error) => {
          console.error("Error fetching landing page data:", error);
        });
    }
  }, [user]);

  // Handle clicks outside of dropdown and logout button
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);  // Close the dropdown if clicked outside
      }
      if (logoutRef.current && !logoutRef.current.contains(event.target)) {
        setIsLogoutVisible(false);  // Close the logout button if clicked outside
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Function to handle login or navigate to the login page
  const handleLoginClick = () => {
    if (user) {
      setIsLogoutVisible(!isLogoutVisible);  // Toggle visibility of the logout button
    } else {
      navigate('/login');
    }
  };

  // Function to handle logout
  const handleLogoutClick = () => {
    logOut();  // Call the logOut function from Auth context
    navigate('/login');  // Navigate to login page after logout
  };

  // Function to navigate to a protected route or redirect to login
  const handleProtectedClick = (path) => {
    if (user) {
      navigate(path);
    } else {
      navigate('/login');  // Redirect to login page if user is not logged in
    }
  };

  // Function to toggle dropdown for days selection
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Function to select a specific day from the dropdown
  const selectDays = (day) => {
    setSelectedDays(day);
    setIsDropdownOpen(false);
  };

  const partTitles = [
    'Read a text aloud',
    'Describe a picture',
    'Respond to questions',
    'Respond to questions using information provided',
    'Express an opinion'
  ];

  // Function to show alert when clicking on the mock test start button
  const handleMockTestClick = (testNumber) => {
    alert(`You clicked on Mock Test ${testNumber}`);
  };

  // Function to handle button click and track event
  const handleButtonClick = () => {
    // Track a custom event in Mixpanel
    mixpanel.track('Landing Page Button Click', {
      button_name: '배토스 피드백 구글 폼',
      user_id: user ? user.user_id : 'guest'  // Add user_id if user is logged in
    });

    // Redirect user to Google Forms
    window.location.href = 'https://forms.gle/JKYYB7r3eaXCdZuBA';
  };

  return (
    <div className="min-h-screen p-4 pb-24 flex flex-col items-center relative font-[Plus Jakarta Sans]">
      {/* Top navigation bar for large screens */}
      <div className="w-full flex justify-end items-center px-4 py-2 bg-white relative">
      {/* Right-aligned navigation buttons and login button */}
      <div className="flex space-x-4 items-center">
        {/* Navigation Buttons for large screens */}
        <button
          className="hidden md:inline-block px-4 py-2 rounded-lg hover:bg-gray-200"
          onClick={() => handleNavigate('/')}
        >
          파트별 문제은행
        </button>
        <button
          className="hidden md:inline-block px-4 py-2 rounded-lg hover:bg-gray-200"
          onClick={() => handleNavigate('/template-practice')}
        >
          템플릿 영작연습
        </button>
        <button
          className="hidden md:inline-block px-4 py-2 rounded-lg hover:bg-gray-200"
          onClick={() => handleNavigate('/mock-test')}
        >
          실전 토스 모의고사
        </button>
        <button
            className="px-4 py-2 rounded-lg"
            style={{ backgroundColor: '#F5F0E5', color: '#1C170D' }}
            onClick={handleLoginClick}
          >
            {user ? `반가워요, ${user.name}!` : 'Login'}
          </button>
      </div>

      {/* Show logout button when user is logged in and the dropdown is open */}
      {isLogoutVisible && (
          <button
            ref={logoutRef}  // Assign ref to logout button
            className="absolute top-12 right-4 mt-2 px-4 py-2 rounded-lg bg-red-500 text-white z-50"
            onClick={handleLogoutClick}
          >
            로그아웃
          </button>
        )}
    </div>

        
     

      {/* Promotional text and Feedback Button in the same row container */}
      <div className="w-full max-w-3xl flex flex-col md:flex-row justify-between items-start md:items-center mt-8 space-y-4 md:space-y-0">
        {/* Left side: Promotional text */}
        <div className="w-full md:w-1/2">
          <h1 className="text-2xl font-bold">토익스피킹 파트별 문제은행 + 실전모의고사 🔥</h1>
          {/* <p className="mt-2 text-lg"> <strong>[Coming Soon] 템플릿 영작 연습+실전모의고사</strong> </p> */}
          <p className="mt-1 text-base">2024 최신경향 파트별 연습 + AI 맞춤형 피드백 + 모범답안</p>
          {/* <p className="mt-1 text-lg">+ AI피드백 + 모범답안 제공</p> */}
          <p className="mt-1 text-base">충분히 연습하고 한 번에 원하는 점수 GET하자. </p>
          <p className="mt-1 text-base">#취준 #승진 배토스가 응원합니다!</p>
        </div>

        {/* Right side: Feedback Button */}
        <div className="w-full md:w-1/2 flex md:justify-end justify-start">
        <button
            className="px-6 py-3 rounded-lg text-white"
            style={{ backgroundColor: '#009963' }}
            onClick={handleButtonClick}  // Attach event tracking to button click
          >
            피드백 남기고 무료 실전 토스모의고사 체험받기!
          </button>
        </div>
      </div>

      {/* Event Countdown and Completed Count in Rounded Boxes */}
      <div className="flex justify-center mt-6 space-x-4">
        {/* Dropdown for countdown */}
        <div className="relative">
          <div
            className="flex flex-col items-center justify-center w-40 h-24 bg-[#F5F0E5]  rounded-lg cursor-pointer"
            // style={{ borderColor: '#E8DECF' }}
            onClick={toggleDropdown}
          >
            <span className="text-3xl  font-bold">{selectedDays}일!</span>
            <span className="text-sm" style={{ color: '#A1824A' }}>시험까지</span>
          </div>

          {/* Dropdown menu */}
          {isDropdownOpen && (
            <div ref={dropdownRef}  // Assign ref to dropdown menu
              className="absolute bg-white  rounded-lg mt-2 w-40 max-h-40 overflow-y-scroll">
              {[...Array(31).keys()].map((day) => (
                <div
                  key={day}
                  className="px-4 py-2 text-center hover:bg-gray-100 cursor-pointer"
                  onClick={() => selectDays(day)}
                >
                  {day}일
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Rounded box for completed questions */}
        <div className="flex flex-col items-center justify-center bg-[#F5F0E5] w-40 h-24 rounded-lg" style={{ borderColor: '#E8DECF' }}>
          <span className="text-3xl font-bold">{completedQuestions}</span>
          <span className="text-sm" style={{ color: '#A1824A' }}>완료한 문제수</span>
        </div>
      </div>

      {/* Part Practice Section */}
      <div className="w-full max-w-2xl mt-8">
        <h2 className="text-xl font-semibold text-center">파트별 연습으로 시험 정복하기</h2>
        
        {/* Adjust the margin for part practice buttons */}
        <div className="mt-8 space-y-8">  {/* Increased margin and spacing between parts */}
          {/* Parts 2 to 5 with routing to description pages */}
          {["p1", "p2", "p3", "p4", "p5"].map((partKey, index) => (
            <div key={index} className="flex justify-between items-center">
              <div>
                <p>Part {index + 1}: {partTitles[index]}</p> {/* Add titles dynamically */}
                <p className="text-sm text-gray-500">
                  {partProgress[partKey]?.completed || 0}/{partProgress[partKey]?.total || 50} questions
                </p>
              </div>
              <button
                className="px-6 py-2 rounded-lg mx-2"
                style={{ backgroundColor: '#F5F0E5', color: '#1C170D' }}
                onClick={() => handleProtectedClick(`/part${index + 1}-description`)}
              >
                Start
              </button>
            </div>
          ))}

          {/* Add Quality AI Mock Tests under Part 5 */}
          <div className="mt-16">  {/* Add extra margin-top */}
            <h3 className="text-xl font-semibold mb-6 text-center">AI 토익 스피킹 모의고사 (베타)</h3>
            
            {[...Array(1).keys()].map((testNumber) => (
              <div key={testNumber} className="flex justify-between items-center mt-4"> 
                {/* Text box for mock test */}
                <div className="flex items-center  px-4 py-2 w-full" style={{ borderColor: '#E8DECF' }}> 
                  <p className="text-lg">모의고사 {testNumber + 1}회</p>
                </div>
                {/* Start button for the mock test */}
                <button
                  className="ml-4 px-6 py-2 rounded-lg text-white"
                  style={{ backgroundColor: '#019863' }}  // Updated button color
                  onClick={() => handleProtectedClick(`/mocktest-description`)}
                  // onClick={() => alert('모의고사는 10월 14일부터 런칭할 예정입니다. 상단의 설문조사를 해주시면 런칭 시, 무료 AI 모의고사권 및 템플릿 영작 연습 크레딧을 보내드립니다!')}

                >
                  Start
                </button>
              </div>
            ))}
          </div>

          {/* New Section: 파트별 템플릿 영작 연습 */}
          <div className="mt-16">  {/* Add some margin-top to separate this section */}
            <h3 className="text-xl font-semibold mb-6 text-center">파트별 템플릿 영작 연습</h3>
            
            {/* Button for 파트2 만능 표현 100가지 연습하기 */}
            <div className="flex justify-between items-center mt-4">
            <div className="flex items-center  px-4 py-2 w-full" style={{ borderColor: '#E8DECF' }}> 
              <p className="text-lg">파트2 만능 표현 정복하기</p>

              </div>
              <button
                className="ml-4 px-6 py-2 rounded-lg text-white"
                style={{ backgroundColor: '#019863' }}
                onClick={() => handleProtectedClick('/part2-template-practice')}
              >
                Start
              </button>
            </div>
            {/* Button for 파트2 만능 표현 100가지 연습하기 */}
            <div className="flex justify-between items-center mt-4">
            <div className="flex items-center  px-4 py-2 w-full" style={{ borderColor: '#E8DECF' }}> 
              <p className="text-lg">파트3 만능 표현 정복하기</p>

              </div>
              <button
                className="ml-4 px-6 py-2 rounded-lg text-white"
                style={{ backgroundColor: '#019863' }}
                onClick={() => handleProtectedClick('/part3-template-practice')}
              >
                Start
              </button>
            </div>

            <div className="flex justify-between items-center mt-4">
            <div className="flex items-center  px-4 py-2 w-full" style={{ borderColor: '#E8DECF' }}> 
              <p className="text-lg">파트5 만능 표현 정복하기</p>

              </div>
              <button
                className="ml-4 px-6 py-2 rounded-lg text-white"
                style={{ backgroundColor: '#019863' }}
                onClick={() => handleProtectedClick('/part5-template-practice')}
              >
                Start
              </button>
            </div>

            
            
          </div>
        </div>
         {/* Footer */}
    <footer className="w-full bg-[#F5F0E5] text-gray-600 mt-12 p-4">
      <div className="max-w-3xl">
        {/* <h2 className="text-sm">배랩 (Bae Lab)</h2> */}
        <p className='text-xs'>문의: <a href="mailto:baetos.kr@gmail.com" className="text-blue-600">baetos.kr@gmail.com</a></p>
        {/* <p className='text-xs'>주소: 부산광역시 해운대구 센텀1로 9</p>
        <p className='text-xs'>사업자등록번호: 538-60-00853</p> */}
      </div>
    </footer>
      </div>
     
      {/* Footer Navigation Bar for small screens */}
      {/* Footer Navigation Bar for small screens */}
<div className="md:hidden fixed bottom-0 w-full bg-white border-t border-[#F5F0E5] shadow-md flex justify-around items-center py-4">
  <button
    className="flex-1 flex flex-col items-center"
    onClick={() => handleNavigate('/')}
  >
    <FolderIcon className="h-6 w-6 text-gray-600" />
    <span className="text-sm mt-1">파트별문제은행</span> {/* Added mt-2 for more space */}
  </button>
  <button
    className="flex-1 flex flex-col items-center"
    onClick={() => handleNavigate('/template-practice')}
  >
    <PencilIcon className="h-6 w-6 text-gray-600" />
    <span className="text-sm mt-1">만능문장연습</span> {/* Added mt-2 for more space */}
  </button>
  <button
    className="flex-1 flex flex-col items-center"
    onClick={() => handleNavigate('/mocktest1')}
  >
    <DocumentTextIcon className="h-6 w-6 text-gray-600" />
    <span className="text-sm mt-1">모의고사</span> {/* Added mt-2 for more space */}
  </button>
</div>
    </div>
  );
};

export default LandingPage;