import { createContext, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";  // Import jwtDecode

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const navigate = useNavigate();

  // Restore user state on page load using token
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      const decodedToken = jwtDecode(storedToken);  // Decode the token to extract user info
      setUser({ user_id: decodedToken.sub, name: decodedToken.name });  // Set user from the decoded token
      setToken(storedToken);
    }
  }, []);

  const loginAction = async (data) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      if (res.access_token) {
        // Decode the token to get user info
        const decodedToken = jwtDecode(res.access_token);
        setToken(res.access_token);
        setUser({ user_id: decodedToken.sub, name: decodedToken.name });  // Set user from decoded token
        localStorage.setItem("token", res.access_token);  // Store token in localStorage
        navigate("/");  // Navigate to home after login
        return true;
      } else {
        throw new Error("Login failed");
      }
    } catch (err) {
      console.error("Login error:", err);
      return false;
    }
  };

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("token");  // Remove token from local storage on logout
    navigate("/login");  // Redirect to login page after logout
  };

  return (
    <AuthContext.Provider value={{ user, token, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);