import React from 'react';
import { useNavigate } from 'react-router-dom';  // You may use this if you have navigation
import { HomeIcon } from '@heroicons/react/24/outline';

const TemplatePractice = () => {
  const navigate = useNavigate();

  // Function to handle button click and navigate
  const handleProtectedClick = (path) => {
    navigate(path);
  };

  return (
    <div className="min-h-screen bg-neutral-50 flex flex-col items-center justify-start p-6">
      {/* Home Icon at the top */}
      <div className="w-full flex justify-start mb-4">
      <button onClick={() => navigate('/')}>
        <HomeIcon className="h-6 w-6 text-gray-700" />
      </button>
    </div>

  
      <h1 className="text-2xl font-bold text-center mb-6">파트별 템플릿 영작 연습 (Beta)</h1>
  
      {/* Section for 파트2 만능 표현 정복하기 */}
      <div className="bg-[#F5F0E5] flex justify-between items-center mt-4 w-full max-w-3xl border border-gray-300 rounded-lg p-4" style={{ borderColor: '#E8DECF' }}>
        <div className="flex items-center w-full">
          <p className="text-lg">파트2 만능 표현 정복하기</p>
        </div>
        <button
          className="ml-4 px-6 py-2 rounded-lg text-white"
          style={{ backgroundColor: '#019863' }}
          onClick={() => handleProtectedClick('/part2-template-practice')}
        >
          Start
        </button>
      </div>

      <div className="bg-[#F5F0E5] flex justify-between items-center mt-4 w-full max-w-3xl border border-gray-300 rounded-lg p-4" style={{ borderColor: '#E8DECF' }}>
        <div className="flex items-center w-full">
          <p className="text-lg">파트3 만능 표현 정복하기</p>
        </div>
        <button
          className="ml-4 px-6 py-2 rounded-lg text-white"
          style={{ backgroundColor: '#019863' }}
          onClick={() => handleProtectedClick('/part3-template-practice')}
        >
          Start
        </button>
      </div>
  
      {/* Section for 파트5 만능 표현 정복하기 */}
      <div className="bg-[#F5F0E5] flex justify-between items-center mt-4 w-full max-w-3xl border border-gray-300 rounded-lg p-4" style={{ borderColor: '#E8DECF' }}>
        <div className="flex items-center w-full">
          <p className="text-lg">파트5 만능 표현 정복하기</p>
        </div>
        <button
          className="ml-4 px-6 py-2 rounded-lg text-white"
          style={{ backgroundColor: '#019863' }}
          onClick={() => handleProtectedClick('/part5-template-practice')}
        >
          Start
        </button>
      </div>
    </div>
  );
};

export default TemplatePractice;