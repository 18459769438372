import React, { useEffect, useState } from 'react';

const Loading = ({ iconPath, text }) => {
    // const [speed, setSpeed] = useState(true); 

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //     setSpeed((prevSpeed) => !prevSpeed); 
    //     }, 1000);

    //     return () => clearInterval(interval);
    // }, []);

  return (
    <div className="flex flex-col items-center justify-center mt-20">
      <img
        src={iconPath}
        alt="Loading"
        className={`w-32 h-32 animate-spin-slow`}
      />
      <p className="text-lg text-black-700 mt-10 font-sans font-semibold">{text}</p>
    </div>
  );
};

export default Loading;
